import styled from "styled-components";


export const SlideContainer = styled.div`
    color: #000;
    background: #fff;
    /* height: 50vh; */
    display: flex;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3rem;
    

`;

export const SlideWrapper = styled.div`
    z-index: 1;
    flex: 1 1 auto;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        padding: 0;
    }
`;

export const SlideRow = styled.div`
    display: grid;

    grid-auto-columns: ${({ imgStart }) => (imgStart ? `60% 40%` : `40% 60%`)};
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
        grid-auto-columns: 1fr;
    }
`;
export const Column1 = styled.div`
    grid-area: col1;
    /* height: 475px; */
    

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        height: minmax(600px, auto);
        padding-bottom: 1rem;
    }

    @media screen and (min-width: 1920px) {
        height: auto;
    }
`;

export const Column2 = styled.div`
    grid-area: col2;
    /* height: 475px; */

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        height: minmax(600px, auto);
        padding-bottom: 1rem;
    }

    @media screen and (min-width: 1920px) {
        height: auto;
    }
`;
export const TextWrapper = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    min-height: 60vh;
    margin-right: 3rem;
    margin-left: 3rem;
    text-align: justify;
`;

export const TextColumn = styled.div`
    display: flex;
    max-width: 80%;
    flex-direction: column;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        max-width: 100%;
    }
`;

export const TopLine = styled.h2`
    padding-top: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: .4rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
`;

export const ParagraphText =styled.p`
    margin-bottom: 1.5rem;
    font-size: 0.8rem;
    line-height: 1.5rem;
    font-family: 'Inter', sans-serif;
`;

export const ImgWrap = styled.div`
    width: 100%;
    
`;

export const Img = styled.img`
    max-width: 100%;
    display: block;
`