import React from 'react'
import { FAQContainer,
         TextWrapper,
         Header,
         Section,
         Title,
         ParagraphText } from './FAQElements';

const FAQ = () => {
    return (
        <FAQContainer id='FAQ'>
            <TextWrapper>
                <Header>FAQ</Header>
                <Section>
                    <Title>Can I still use my images and video even if they've been accepted by an agent?</Title>
                    <ParagraphText>Absolutely! Your content will be licensed to customers worldwide on your behalf while you maintain the copyright.</ParagraphText>
                </Section>
                <Section>
                    <Title>Can I upload past client work?</Title>
                    <ParagraphText>Yes, if the client approves. We inform the agent that a specific client has temporary rights to the images before uploading.</ParagraphText>
                </Section>
                <Section>
                    <Title>What can I shoot?</Title>
                    <ParagraphText>Anything! That's the best part, total creative freedom. But the more universal the appeal and the more conceptual the content, the more likely sales will soon materialize.</ParagraphText>
                </Section>
                <Section>
                    <Title>How do I submit content to Talent for Adventure?</Title>
                    <ParagraphText>Google Drive, DropBox, WeTransfer, Hard Drive.</ParagraphText>
                </Section>
            </TextWrapper>
        </FAQContainer>
       
    )
}

export default FAQ
