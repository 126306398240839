import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../theme'
import Navbar2 from '../components/Navbar2';
import ContactMain from '../components/Contact';
import Footer from '../components/Footer';
import SidebarTwo from '../components/SidebarTwo';

const Contact = ({isOpen, toggleSidebar}) => {

    return (
        <ThemeProvider theme={theme}>
            <SidebarTwo isOpen={isOpen} toggle={toggleSidebar} />
            <Navbar2 toggle={toggleSidebar} />
            <ContactMain />
            <Footer />
        </ThemeProvider>
    )
}

export default Contact
