import styled from "styled-components";

export const TitleWrapper = styled.div`
    display: flex;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        padding-top: 80px;
        text-align: center;
    }
`;

export const TutorialGrid = styled.div`
    display: grid;
    width: 80%;
    padding-top: 2rem;
    flex-direction: column;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto;
    grid-gap: 1rem;
    padding-bottom: 1.5rem;
    justify-items: center;
    align-items: center;

    @media screen and (max-width: 1400px) {
        /* grid-template-columns: 100%; */
        display: block;
    }
`