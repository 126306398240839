import React from 'react'
import TutorialContent from '../TutorialContent';
import { ElementWrapper,
         Title,
    } from '../SubmissionDetails/SubmissionElements';
import { TutorialGrid, TitleWrapper, } from './tutorialElements';
import { TutorialOne, TutorialTwo, TutorialThree} from '../Data';



const Tutorials = () => {
  return (
    <ElementWrapper>
        <TitleWrapper>
            <Title>Video Tutorials</Title>
        </TitleWrapper>
        <TutorialGrid>
            <TutorialContent {...TutorialOne}/>
            <TutorialContent {...TutorialTwo}/>
            <TutorialContent {...TutorialThree} />
        </TutorialGrid>
    </ElementWrapper>
  )
}

export default Tutorials