import React, {useState} from 'react'
import Home from './pages'
import Contact from './pages/contact'
import Contract from './pages/contract'
import Contributor from './pages/contributors'
import UploadInfo from './pages/uploadinfo'
import Earnings from './pages/earnings'
import Tutorials from './pages/tutorials'
import { BrowserRouter as Router, Route } from 'react-router-dom'





const App = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen)
    };

    return (
            <Router>
                <Route path ='/' exact>
                    <Home isOpen={isOpen} toggleSidebar={toggleSidebar} />
                </Route>
                <Route path='/contact'>
                    <Contact isOpen={isOpen} toggleSidebar={toggleSidebar} />
                </Route>
                <Route path='/contract'>
                    <Contract isOpen={isOpen} toggleSidebar={toggleSidebar} />
                </Route>

                <Route path='/contributor'> 
                    <Contributor isOpen={isOpen} toggleSidebar={toggleSidebar} />
                </Route>
                <Route path='/uploadinfo'>
                    <UploadInfo isOpen={isOpen} toggleSidebar={toggleSidebar} />
                </Route>
                <Route path='/earningsinfo'>
                    <Earnings isOpen={isOpen} toggleSidebar={toggleSidebar} />
                </Route>
                <Route path='/tutorials'>
                    <Tutorials isOpen={isOpen} toggleSidebar={toggleSidebar} />
                </Route>
            </Router>
    )
}

export default App

