import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../theme'
import Navbar2 from '../components/Navbar2';
import PDFViewer from '../components/PDFViewer';
import Footer from '../components/Footer';
import SidebarTwo from '../components/SidebarTwo';


const Contract = ({isOpen, toggleSidebar}) => {

    return (
        <ThemeProvider theme={theme}>
            <SidebarTwo isOpen={isOpen} toggle={toggleSidebar} />
            <Navbar2 toggle={toggleSidebar} />
            <PDFViewer />
            <Footer />
        </ThemeProvider>
    )
}

export default Contract