import React from 'react'
import SinglePage from './PDFSinglePage'
import AllPages from './PDFAllPages'
import contractPDF from '../../images/Contract/tfacontract.pdf'
import {Wrapper, Title, PDFWrapper} from './PDFViewerElements'

import './styles.css';

const PDFViewer = () => {
  return (
    <Wrapper>
        <Title>Full Length Contract</Title>
        <PDFWrapper>
            {/* <AllPages pdf={contractPDF} /> */}
            <SinglePage pdf={contractPDF} />
        </PDFWrapper>
    </Wrapper>

  )
}

export default PDFViewer