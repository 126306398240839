import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';

export const FooterContainer = styled.footer`
    color: #000;
    width: 100%;
    `

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* max-width: 1100px; */
    margin: 0 auto;
`

export const FooterLinksContainer = styled.div`
    display: flex;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        padding-top: 32px;
    }
`

export const FooterLinksWrapper = styled.div`
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        flex-direction: column;
    }
`

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 1rem;
    text-align: left;
    width: 10rem;
    box-sizing: border-box;

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`

export const FooterLinkTitle = styled.h1`
    font-size: 1.2rem;
    letter-spacing: .3rem;
    margin-bottom: 1rem;
`

export const FooterLink = styled(Link)`
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;

    &:hover {
        color: ${({theme}) => theme.secondaryHover};
        transition: 0.3s ease-out;
    }
`

export const FooterLinkEx =styled.a`
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;

    &:hover {
        color: ${({theme}) => theme.secondaryHover};
        transition: 0.3s ease-out;
    }
`


export const SocialMedia = styled.section`
    /* max-width: 1100px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-basis: 100%;
    flex: 1;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        flex-direction: column;
        justify-content: center;
    }
`

export const SocialMediaWrap = styled.div`
    justify-content: space-between;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }
`
export const RightsWrapper = styled.div`
   margin-top: auto;
   margin-bottom: auto;

`

export const WebsiteRights = styled.small`
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: .8rem;
    margin-left: 5rem;

`

export const SocialIcons = styled.div`
   margin-top: auto;
   margin-bottom: auto;
   margin-right: 5rem;
   display: flex;

    @media screen and (max-width: ${({theme}) => theme.tablet}) {
        justify-content: center;
        margin-right: auto;
    }
`

export const SocialIconLink = styled.a`
    color: #000;
    font-size: 1.5rem;
    padding-left: 1rem;
`
