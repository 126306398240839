import styled from 'styled-components';


export const FAQContainer=styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: justify;
    height: auto;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding-top: 4rem;
    padding-bottom: 4rem;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }
`;
export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        width: 50%;
    }
    
`;

export const Header = styled.h2`
 letter-spacing: 0.5rem;
 text-align: center;
`;

export const Section = styled.div`
    padding-top: 1.5rem;
    
`;

export const Title = styled.h3`
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    padding-bottom: 1rem;
`;

export const ParagraphText = styled.text`
 margin-bottom: 1.5rem;
 font-size: 0.8rem;
 line-height: 1.5rem;
 font-family: 'Inter', sans-serif;

`;