import React from 'react'
import { ElementWrapper, 
         TitleWrapper,
         Title,
         ContentWrapper, 
         ContentSection,
         ContentText,
         Minititle,
         ReleasesSection,
         SubmissionFAQContainer,
         BulletTitle,
         BulletContent } from './SubmissionElements'

const SubmissionDetails = () => {
    return (
        <ElementWrapper>
            <TitleWrapper>
                <Title>Upload FAQ</Title>
            </TitleWrapper>
            <ContentWrapper>
                <ContentSection>
                    <Title>Photo</Title>
                    <ContentText><Minititle>Submitting: </Minititle>Photo submissions should be submitted via notifing info@talentforadventure.com</ContentText>
                    <ContentText><Minititle>File Dimensions and Format: </Minititle>JPEG minimum 5000 pixels on the long side, except square images, which
                        should be 3500x3500 pixels or greater (note that exceptional content captured in lower resolutions than
                        these may be acceptable; in such cases, we will interpolate files upwards to meet agency requirements).</ContentText>
                </ContentSection>
                <ContentSection>
                    <Title>Video</Title>
                    <ContentText><Minititle>Submitting: </Minititle>Video can be submitted to us with a download link from the cloud service of your choice or
                    we can grant you access to an unlimited storage google drive upload folder.</ContentText>
                    <ContentText><Minititle>Formats: </Minititle>Video can be submitted in any format out of camera. Please avoid compressing footage before
submitting.</ContentText>
                    <ContentText><Minititle>Frame Rates: </Minititle>24, 25, 30, 50, 60, 120 and higher frame rates (or fractions thereof) are all acceptable
during submission. Any frame rates lower than 23.97 frames per second will be rejected, however.</ContentText>
                    <ContentText><Minititle>Clip Length: </Minititle>Minimum 5 seconds in length; only extremely unique files under 5 seconds will be
considered.</ContentText>
                    <ContentText><Minititle>Dimensions: </Minititle>Please submit your video in conventional widescreen resolutions, (1920x1080, 3840X2160,
4096X2160 , etc.). Vertical versions of these ratios are acceptable.</ContentText>
                </ContentSection>
                <ContentSection>
                    <Title>Metadata and Releases</Title>
                    <ContentText><Minititle>Metadata: </Minititle>Please sort submission folders by country or location so we can properly fill out Getty’s
required metadata.</ContentText>
                    <ContentText><Minititle>Releases: </Minititle>Industry standard approved releases for property and models are required for photo and video
submissions. We recommend the Easy Release iPad/iPhone app to facilitate the release
process (please contact us for more info regarding these release forms; also, for release copies in
languages other than English).</ContentText>
                </ContentSection>
                <SubmissionFAQContainer>
                   <Title>Submission FAQs</Title>
                   <Minititle>Do I need to provide releases at the time of submission?</Minititle>
                   <BulletContent>a. Yes, both model and property releases for recognizable persons and property.</BulletContent>
                   <Minititle>Do persons shot from behind need a release? </Minititle>
                   <BulletContent>a. Yes, whenever they’re recognizable.</BulletContent>
                   <Minititle>Do property interiors need a corresponding property release? </Minititle>
                   <BulletContent>a. Not always, unless there are recognizable or trademarked features included.</BulletContent>
                   <Minititle>Do I have to submit similar clips at the same time?</Minititle>
                   <BulletContent>a. Yes, in all cases.</BulletContent>
                   <Minititle>Can I submit video from my smart phone?</Minititle>
                   <BulletContent>a. If the footage is exceptional then smart phone quality is acceptable; otherwise the
sensor will likely produce an inadequate result.</BulletContent>
                   <Minititle>Do I need to include camera movement in all of my video shots? </Minititle>
                   <BulletContent>a. No, although we encourage camera movement when it makes sense; “lock-offs” are
often viable, and sometimes work best.</BulletContent>
                   <Minititle>Is 4K resolution important?</Minititle>
                   <BulletContent>a. Yes! We encourage all filmmakers to shoot at 4K resolution whenever possible. If
nothing more, it will ‘future-proof’ your video work, thus safeguarding your investment.</BulletContent>
                   <Minititle>Is higher than 4K resolution important?</Minititle>
                   <BulletContent>a. No – at least for the foreseeable future. All 5K, 6K or 8K work currently has to be
rendered to 4K in any case during the final submission process to our agents.</BulletContent>
                </SubmissionFAQContainer>
            </ContentWrapper>
        </ElementWrapper>
    )
}

export default SubmissionDetails
