import React from 'react';
import { IconContext } from 'react-icons/lib';
import { Nav,
         NavLogo, 
         Logo,
        } from './Navbar2Elements';


const Navbar = ({ scrollNav, toggleHome }) => {
    
    return (
        <>
        <IconContext.Provider value={{ color: '#fff'}}>
        <Nav scrollNav={scrollNav}>
                <NavLogo to='/' onClick={toggleHome} scrollnav={scrollNav}>
                    <Logo>TFA</Logo>
                </NavLogo >
        </Nav>
        </IconContext.Provider>
        </>
    )
}

export default Navbar
