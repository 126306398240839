import styled from "styled-components";


export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

`
export const Title = styled.h4`
    justify-self: center;
    margin-left: auto;
    margin-right: auto;
    align-text: left;
    padding-top: 80px;
`

export const PDFWrapper = styled.div`


`;