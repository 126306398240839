import React from 'react'
import { HeroContainer,
         HeroContent,
         HeroH1,
         HeroP } from './HeroElements';

const HeroSection = () => {
    return (
        <HeroContainer id="home">
             <HeroContent>
                 <HeroH1>Talent For Adventure</HeroH1>
                 <HeroP>Freedom to Explore and Create</HeroP>
             </HeroContent>
         </HeroContainer>
    )
}

export default HeroSection
