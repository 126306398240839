
// Homepage Slides -- Photo and text

export const SlideOne = {
    id: 'about',
    header: 'WHAT IS Talent for Adventure?',
    description1: 'Talent for Adventure is a stock photography service founded by dedicated photographers and filmmakers.',
    description2: 'We strive to build media collections on a number of the highest tier stock platforms. We manage all aspects of post production for our global community of contributors. This includes file uploading, trimming, editing, color grading, file naming, photoshopping and keywording. We also attend to every related business need, such as sales reporting, usage negotiations, asset management, and payments.',
    description3: 'With Talent for Adventure representation, you can focus on shooting and living adventures and let us handle the rest!',
    imgStart: true,
    img: require('../images/AXM-PKS-30902.jpg').default,
    alt: 'mountain scenic',

}

export const SlideTwo = {
    id:'services',
    header: 'WHY WORK WITH Talent for Adventure?',
    description1:'With a huge demand for online content in the marketplace, your images or video clips could be circulating the commercial & editorial world and building you a passive income, rather than be buried in aging archives.',
    description2:'Also - going forward - shooting new stock media allows you to create work in your own time and with total creative freedom.',
    description3: null,
    imgStart: false,
    img: require('../images/night-light.jpg').default,
    alt:'base jumper leaps into sunglight'
}

export const SlideThree = {
    id: null,
    header: 'Generate passive income with your media',
    description1:'Your photos and videos have the potential to be earning you a passive income. We specialize in maximizing the return you can earn from your media.',
    description2:"We'll help you establish and grow a steady scalable source of passive income tailored to your specific shooting style.",
    description3:'All you have to do is submit your media and we’ll make sure it enters the marketplace and gets sold. ',
    imgStart: true,
    img: require('../images/TFA--AI-0001.jpg').default,
    alt: 'placeholder image'
}

export const SlideFour = {
    id: null,
    header:'What can I earn?',
    description1:'Through our many agencies and market reach, you are guaranteed a higher royalty rate and higher sales volume working with us than you would be by uploading directly, while at the same time getting your work submitted to multiple agents.',
    description2:'So working with TFA is easier, faster and more profitable than going at it alone.',
    description3:'Our royalty rates are broken down into brackets, ensuring that you take home a bigger cut as you accumulate revenue.',
    imgStart: false,
    img: require('../images/2C2A1506.jpg').default,
    alt:'placeholder image'
}

export const SlideFive = {
    id:'temp',
    header: 'WHERE WILL MY MEDIA GO?',
    description1:'Your work will be submitted to the highest-priced channels including Offset, Shutterstock select, and others. The combined distribution network of channels ensures your work is seen by the maximum number of potential customers globally.',
    description2:'Your images can be purchased for advertising, marketing, apps, websites, social media, TV, and movie productions and many other uses.',
    description3:'When a buyer chooses media, they purchase a license that allows them to download your files for commercial use - resulting in a sale for you, the contributor.',
    imgStart: true,
    img: require('../images/TFA-PK-1036 copy.jpg').default,
    alt:'another-placeholder' 
}


//Tutorial Videos
export const TutorialOne = {
    titleText:"Where to Begin?",
    contentText:"If you haven't submitted stock media before and aren't sure where to start, this video should provide you with some insight on where to begin:",
    videoUrl:"https://www.youtube.com/embed/rSspFfgOYOA",
    videoTitle:"Media Selection Tutorial",
    
}

export const TutorialTwo = {
    titleText:"how to Upload",
    contentText:"If you're a new contributor or returning after a hiatus we have a tutorial on how best to upload and organzie your media for editing:",
    videoUrl:"https://www.youtube.com/embed/Mp-RyQhRAaM",
    videoTitle:"How to Upload Tutorial",
    
}

export const TutorialThree = {
    titleText:"Preparing Model Releases",
    contentText:"Every image or video clip with a recognizeable person in it will need a model release filled out. This can be a confusing process as there are several essential fields to fill out, this video explains how to fill out your releases with the Easy Release App.",
    videoUrl:"https://www.youtube.com/embed/kCp8-s1D_9Q",
    videoTitle:"Releases Tutorial"
}


// Contributor Profile Slides

export const Contributor1 = {
    rowSlot: 1,
    photo: require('../images/Contributors/Julia-reg.png').default,
    alt:'Jules Photo',
    contribName: 'Julia Regehr-Smith',
    contribDescription: 'https://www.instagram.com/julesgypsy/?hl=en'
}

export const Contributor2 = {
    rowSlot: 2,
    photo: require('../images/Contributors/Milo.png').default,
    alt:'Milo Zanecchia Photo',
    contribName: 'Milo Zanecchia',
    contribDescription: 'http://milozanecchia.com/'
}

export const Contributor3 = {
    rowSlot: 3,
    photo: require('../images/Contributors/bfc.png').default,
    alt:'bfc Logo',
    contribName: 'Black Forest Collective',
    contribDescription: 'https://bfc.film/'
}

export const Contributor4 = {
    rowSlot: 4,
    photo: require('../images/Contributors/wsm.png').default,
    alt:'WSM Photo',
    contribName: 'Wintersun Media',
    contribDescription: 'https://www.instagram.com/joshsmiht/'
}


export const Contributor5 = {
    rowSlot: 1,
    photo: require('../images/Contributors/Andrii.png').default,
    alt:'Andrii Lutsyk Photo',
    contribName: 'Andrii Lutsyk',
    contribDescription: 'https://www.andriilutsyk.com/',
}

export const Contributor6 = {
    rowSlot: 2,
    photo: require('../images/Contributors/alebeltrame.png').default,
    alt:'Alessandro Beltrame Photo',
    contribName: 'Alessandro Beltrame',
    contribDescription: 'http://www.agbvideo.com/',
}

export const Contributor7 = {
    rowSlot: 3,
    photo: require('../images/Contributors/Craig-Lugs.png').default,
    alt:'Craig Lugsden Photo',
    contribName: 'Craig Lugsden',
    contribDescription: 'https://clugsdenimagery.myportfolio.com/home',
}

export const Contributor8 = {
    rowSlot: 4,
    photo: require('../images/Contributors/Enrico-Sme.png').default,
    alt:'Enrico Profile',
    contribName: 'Enrico Smeraldi',
    contribDescription: 'https://en.enrico-smeraldi.com/',
}

// export const Contributor9 = {
//     rowSlot: 4,
//     photo: require('../images/Contributors/Clement.png').default,
//     alt:'Clement Hodgkinson Photo',
//     contribName: 'Clement Hodgkinson',
//     contribDescription: 'https://www.clementhodgkinson.com/',
// }

// export const Contributor10 = {
//     rowSlot: 1,
//     photo: require('../images/Contributors/Reuben.png').default,
//     alt:'Andrii Profile',
//     contribName: 'East Road',
//     contribDescription: 'www.ascentxmedia.com',
// }

export const Contributor11 = {
    rowSlot: 1,
    photo: require('../images/Contributors/Fabian-euq.png').default,
    alt:'Fabian Profile',
    contribName: 'Fabian Equey',
    contribDescription: 'https://www.fabianequey.com/',
}

export const Contributor12 = {
    rowSlot: 2,
    photo: require('../images/Contributors/Florence-bef.png').default,
    alt:'Florence Beffa Photo',
    contribName: 'Florence Beffa',
    contribDescription: 'https://www.florencebeffa.com/',
}

// export const Contributor13 = {
//     rowSlot: 1,
//     photo: require('../images/Contributors/Reuben.png').default,
//     alt:'Andrii Profile',
//     contribName: 'Giorgio Bernardi',
//     contribDescription: 'www.ascentxmedia.com',
// }

export const Contributor14 = {
    rowSlot: 3,
    photo: require('../images/Contributors/GregJacobs.png').default,
    alt:'Greg Jacobs Photo',
    contribName: 'Greg Jacobs',
    contribDescription: 'https://www.capturesharerepeat.com/',
}

export const Contributor15 = {
    rowSlot: 4,
    photo: require('../images/Contributors/Jarno-cor.png').default,
    alt:'Jarno Cordia Photo',
    contribName: 'Jarno Cordia',
    contribDescription: 'https://linktr.ee/mccordia',
}

// export const Contributor16 = {
//     rowSlot: 4,
//     photo: require('../images/Contributors/Reuben.png').default,
//     alt:'Andrii Profile',
//     contribName: 'Graham Pearsall',
//     contribDescription: 'www.ascentxmedia.com',
// }

export const Contributor17 = {
    rowSlot: 1,
    photo: require('../images/Contributors/jharris.png').default,
    alt:'Jon Harris Profile',
    contribName: 'Jon Harris',
    contribDescription: 'https://www.instagram.com/jonharrris/?hl=en',
}

// export const Contributor18 = {
//     rowSlot: 2,
//     photo: require('../images/Contributors/Reuben.png').default,
//     alt:'Karolin Neuffer Photo',
//     contribName: 'Karolin Neuffer',
//     contribDescription: 'www.ascentxmedia.com',
// }

export const Contributor19 = {
    rowSlot: 2,
    photo: require('../images/Contributors/kledeboer.png').default,
    alt:'Kyle Ledeboer Photo',
    contribName: 'Kyle Ledeboer',
    contribDescription: 'https://www.kplstudios.com/',
}

export const Contributor20 = {
    rowSlot: 3,
    photo: require('../images/Contributors/Luke-jar.png').default,
    alt:'Luke Jarmey Photo',
    contribName: 'Luke Jarmey',
    contribDescription: 'https://lukejarmey.com/',
}

export const Contributor21 = {
    rowSlot: 4,
    photo: require('../images/Contributors/Martin-bis.png').default,
    alt:'Martin Bissig Photo',
    contribName: 'Martin Bissig',
    contribDescription: 'https://www.bissig.ch/',
}

export const Contributor22 = {
    rowSlot: 1,
    photo: require('../images/Contributors/ollie.png').default,
    alt:'Oliver Furrer Photo',
    contribName: 'Oliver Furrer',
    contribDescription: 'http://www.oliverfurrer.com/',

}

export const Contributor23 = {
    rowSlot: 2,
    photo: require('../images/Contributors/redesign-sui.png').default,
    alt:'Redesign Suisse Photo',
    contribName: 'Redesign Suisse',
    contribDescription: 'https://www.redesign.swiss/',
}

export const Contributor24 = {
    rowSlot: 3,
    photo: require('../images/Contributors/Rene-Ter.png').default,
    alt:'Rene Terstegen Photo',
    contribName: 'Rene Terstegen',
    contribDescription: 'https://www.instagram.com/troublewithrene/?hl=en',
}

export const Contributor25 = {
    rowSlot: 4,
    photo: require('../images/Contributors/Reto-Nyf.png').default,
    alt:'Reto Nyffenegger Photo',
    contribName: 'Reto Nyffenegger',
    contribDescription: 'https://www.instagram.com/retonyffenegger/?hl=en',
}

export const Contributor26 = {
    rowSlot: 1,
    photo: require('../images/Contributors/Reuben.png').default,
    alt:'Reuben Krabbe Photo',
    contribName: 'Reuben Krabbe',
    contribDescription: 'https://reubenkrabbe.com/',
}

export const Contributor27 = {
    rowSlot: 2,
    photo: require('../images/Contributors/simonhird.png').default,
    alt:'Simon Hird Photo',
    contribName: 'Simon Hird',
    contribDescription: 'https://simonhird.com/',
}

export const Contributor28 = {
    rowSlot: 3,
    photo: require('../images/Contributors/Simon-Str.png').default,
    alt:'Simon Straetker Photo',
    contribName: 'Simon Straetker',
    contribDescription: 'http://www.simonstraetker.com/',
}

export const Contributor29 = {
    rowSlot: 4,
    photo: require('../images/Contributors/tkp.png').default,
    alt:'Taja KP',
    contribName: 'Taja Košir Popovič',
    contribDescription: 'https://tajakp.com/',
}

export const Contributor30 = {
    rowSlot: 1,
    photo: require('../images/Contributors/Thor-mie.png').default,
    alt:'Thor Mieglitz Photo',
    contribName: 'Thor Mieglitz',
    contribDescription: 'https://www.instagram.com/ilovemywings/',
}

export const Contributor31 = {
    rowSlot: 2,
    photo: require('../images/Contributors/Tim-How.png').default,
    alt:'Tim Howell Photo',
    contribName: 'Tim Howell',
    contribDescription: 'http://timhowelladventure.com/',
}


